<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-1" title="Detail Transaksi Pembayaran" ok-only ok-title="Accept" size="xl"
            no-close-on-backdrop hide-footer>
            <b-row>
                <b-col md="8">
                    <b-card>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25 text-primary">{{ rs_pembayaran.nim }}</h5>
                                <small>Nama</small>
                                <h5>{{ rs_pembayaran.nm_lengkap }}<br> <small>Mahasiswa kelas
                                        <strong>{{ rs_pembayaran.kelas }}</strong>, tahun angkatan
                                        <strong>{{ rs_pembayaran.th_angkatan }}</strong></small></h5>
                            </b-col>
                            <b-col md="6">
                                <h5 class="mt-1 ">
                                    Informasi Pembayaran :
                                </h5>
                                <small class="mt-5 mt-5">Pembayaran</small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_jenis_pembayaran }} - Kelas
                                    {{ rs_pembayaran.kelas }} </h5>
                                <small>Periode Semester </small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_semester }}</h5>

                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="5" class="mb-4">
                                <b-row>
                                    <b-col>
                                        <h5>File Bukti Bayar</h5>
                                        <small class="mt-5 mt-5">Tanggal Bayar
                                            <strong>{{ rs_pembayaran.tgl_pembayaran }}</strong> </small>
                                        <img :src="rs_pembayaran.file" width="100%">
                                    </b-col>
                                </b-row>
                                <div v-if="rs_pembayaran.status == 'Ditolak'">
                                    <b-button block variant="danger">Pembayaran Ditolak</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status == 'Valid'">
                                    <b-button block variant="primart">Pembayaran Tervalidasi</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status == 'Revisi'" class="mt-1">
                                    <b-button block variant="warning"> Revisi </b-button>
                                </div>
                            </b-col>
                            <b-col md="7" class="mb-5">
                                <div>
                                    <h6> Invoice {{ rs_pembayaran.no_inv }}</h6>
                                    <h6 class=" ">Bank {{ rs_pembayaran.bank }} -
                                        {{ rs_pembayaran.no_rekening }}</h6>
                                    <small>Atasnama : <strong>{{ rs_pembayaran.atas_nama }}</strong> </small>
                                </div>
                                <h6 class=" ">
                                    Detail Pembayaran :
                                </h6>

                                <b-row>
                                    <b-col md="6">
                                        <small class="mt-5 mt-5">Tagihan </small>
                                        <h3 class="text-warning">
                                            <sup>Rp.</sup> <strong>{{ Rp(rs_pembayaran.tertagih) }},-</strong>
                                        </h3>
                                    </b-col>
                                    <b-col md="6">
                                        <small class="mt-5 mt-5">Terbayar </small>
                                        <h3 class="text-primary">
                                            <sup>Rp.</sup> <strong>{{ Rp(rs_pembayaran.terbayar) }},-</strong>
                                        </h3>
                                    </b-col>
                                </b-row>
                                <div class="mt-1">
                                    <h5>keterangan </h5>
                                    <p v-html="rs_pembayaran.keterangan"> </p>
                                    <h5>ketentuan Jenis pembayaran</h5>
                                    <p v-html="rs_pembayaran.keterangan_jenis_pembayaran"> </p>

                                </div>
                            </b-col>

                        </b-row>

                    </b-card>
                </b-col>
                <b-col md="4">


                    <timeline :histori_pembayaran=histori_pembayaran :rs_pembayaran=rs_pembayaran></timeline>
                </b-col>

            </b-row>
            <b-card-text>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BFormTextarea,
        BCardText,
        BCard
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import timeline from './timeline.vue'

    export default {
        components: {
            BButton,
            BModal,
            BAlert,
            BRow,
            BCol,
            timeline,
            BFormTextarea,
            BCardText,
            ValidationProvider,
            ValidationObserver,
            BCard
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            histori_pembayaran: {},
            rs_pembayaran: {},
        },
        data() {
            return {
                input: {},
                required,
                keterangan: ""
            }
        },
        emits: ['konfirmasiPembayaran'],
        methods: {
            validationForm(val) {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        if (confirm('Apakah anda yakin melakukan memproses pembayaran ini..?') ==
                            true) {
                            this.$emit('konfirmasiPembayaran', this.rs_pembayaran.id_transaksi_pembayaran, val,
                                this.keterangan);
                            this.close();
                            this.keterangan = "";
                        }
                    }

                })
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1')
            },
        }
    }
</script>