<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="8"> 
            <b-form-input id="basicInput" v-model="nim" placeholder="Inputkan Nim" /> 
        </b-col>
        <b-col md="2">
          <b-button block variant="primary" @click="cariData()">
            <feather-icon size="15" icon="SearchIcon"  />  Pencarian
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button block variant="warning" @click="clear()">
            <feather-icon size="15" icon="MehIcon"  /> Clear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-col md="12">

        <daftar-mahasiswa  :items=detail_list @get_histori_pembayaran=get_histori_pembayaran></daftar-mahasiswa>
        <detail_pembayaran :histori_pembayaran=histori_pembayaran :rs_pembayaran=rs_detail
          ></detail_pembayaran>

      </b-col>
    </b-card> 

  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import axios from '@/config/Axios'
  import detail_pembayaran from './component/detail_pembayaran.vue'
  import {
    BRow,
    BCol,
    BCard, BFormInput,
    BFormGroup,BButton
  } from 'bootstrap-vue'
  import BelumValidasi from './component/belumValidasi.vue'
  import daftarPeriodePembayaran from './component/daftarPeriodePembayaran.vue'
  import DaftarMahasiswa from './component/DaftarMahasiswa.vue'
  export default {
    components: {
      BRow,
      BCol,BButton,
      BCard,
      BelumValidasi,
      daftarPeriodePembayaran,
      DaftarMahasiswa,
      detail_pembayaran, BFormInput,
    BFormGroup,
    },
    data() {
      return {
        nim : "", 
        detail_list: [],
        rs_detail :{},
        histori_pembayaran: {}, 
        id_periode_pembayaran: '', 
      }
    },
    mixins: [Base],
    mounted() {
      
    },
    methods: { 
      async cariData() {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/pembayaran/cek_status_pembayaran/daftar_tagihan',
            data: {
              nim : self.nim
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) { 
            self.detail_list = response.data.result; 
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
     
      async get_histori_pembayaran(val) {
        this.rs_detail = val;
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pembayaran/validasi_pembayaran/histori_pembayaran',
            data: {
              id_transaksi_pembayaran: val.id_transaksi_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.histori_pembayaran = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      clear(){
        this.nim = '';
        this.detail_list  = [];
      }
    }
  }
</script>

<style>

</style>